@font-face {
  font-family: rubik-regular;
  src: url("Rubik-Regular.5480d46b.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: rubik-light;
  src: url("Rubik-Light.24561d14.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: rubik-medium;
  src: url("Rubik-Medium.f41d6a5c.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: rubik-bold;
  src: url("Rubik-Bold.064015e4.ttf") format("truetype");
  font-style: normal;
}

/*# sourceMappingURL=index.d40b6012.css.map */
