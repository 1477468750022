@import "constants";

@font-face {
  font-family: $main-font;
  src: url("../fonts/Rubik-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $main-font-light;
  src: url("../fonts/Rubik-Light.ttf") format("truetype");
  font-weight: 300 !important;
  font-style: normal;
}

@font-face {
  font-family: $main-font-medium;
  src: url("../fonts/Rubik-Medium.ttf") format("truetype");
  font-weight: $weight-medium !important;
  font-style: normal;
}

@font-face {
  font-family: $main-font-bold;
  src: url("../fonts/Rubik-Bold.ttf") format("truetype");
  font-weight: $weight-bold !important;
  font-style: normal;
}
